import { connect } from 'react-redux';
import * as documents from '../../controllers/actions/document.actions';
import InsideProjectBox from '../components/InsideProjectList/components/InsideProjectBox';
import * as modal from '../../controllers/actions/modal.actions';
import { openFeatureUpgrade } from '../../controllers/actions/pricing.actions';
import { modalsIds } from '../../constants/modal.constants';
import { types as projectTypes } from '../../constants/projects.constants';
import { FEATURES, FEATURE_STATUS } from '../../constants/features.constants';
import { isAccountParked } from '../../utils/account';

export const mapStateToProps = (state) => {
  const { currentDocumentsActions, removingCounters, errors } = state.documentActions;
  const { features } = state.features;
  const { commentsCount, project } = state.insideProject;
  const { features: { approval_process: approvalProcess } } = state.features; // eslint-disable-line camelcase
  const { data } = state.currentUser;
  // eslint-disable-next-line max-len
  const { permissions: { can_add_edit_delete_approvals: canAddEditDeleteApprovals, has_access_to_editor: hasAccessToEditor } } = state.permissions;
  const isDiscover = project.type === projectTypes.DISCOVER_ONBOARDING_COLLECTION;

  return {
    currentDocumentsActions,
    removingCounters,
    errors,
    features,
    commentsCount,
    isAccountParked: isAccountParked(data.idActiveAccount, data.accounts),
    isApprovalProcessEnabled: approvalProcess === FEATURE_STATUS.ENABLED,
    hasAccessToEditor: hasAccessToEditor && hasAccessToEditor.selected !== 'no',
    canAddEditDeleteApprovals: canAddEditDeleteApprovals && canAddEditDeleteApprovals.selected === 'yes',
    isDiscover,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  openModalboxDocumentDelete: (document) => {
    dispatch(modal.openModal(modalsIds.MODALBOX_DOCUMENT_DELETE, document));
  },

  openModalboxDocumentChangeName: (document) => {
    dispatch(modal.openModal(modalsIds.MODALBOX_DOCUMENT_CHANGE_NAME, document));
  },

  openModalboxDocumentChangeProject: (document) => {
    dispatch(modal.openModal(modalsIds.MODALBOX_DOCUMENT_CHANGE_PROJECT, document));
  },

  openApprovalProcess: (document) => {
    dispatch(modal.openModal(modalsIds.APPROVAL_PROCESS, document));
  },

  restoreDocument: (idDocument) => {
    dispatch(documents.restoreDocument(idDocument));
  },

  discardFailedReimport: (idDocument) => {
    dispatch(documents.discardFailedReimport(idDocument));
  },

  discardFailedSketchImport: (idDocument) => {
    dispatch(documents.discardFailedSketchImport(idDocument));
  },

  cancelConversion: (idDocument) => {
    dispatch(documents.cancelConversion(idDocument));
  },

  cancelDuplication: (idDocument) => {
    dispatch(documents.cancelDuplication(idDocument));
  },

  deleteDocument: (idDocument) => {
    dispatch(documents.deleteDocument(idDocument));
  },

  removeDocumentPermanently: (idDocument) => {
    dispatch(documents.removeDocumentPermanently(idDocument));
  },

  stopActionOnDocument: (idDocument) => {
    dispatch(documents.stopActionOnDocument(idDocument));
  },

  duplicateDocument: (idDocument, noIterations = false) => {
    dispatch(documents.duplicateDocument(idDocument, noIterations));
  },

  requestCheckingDuplicationStatus: (idDocument) => {
    dispatch(documents.requestCheckingDuplicationStatus(idDocument));
  },

  openFeatureUpgrade: () => {
    dispatch(openFeatureUpgrade(FEATURES.APPROVAL_PROCESS));
  },
});

export const DocumentBox = connect(mapStateToProps, mapDispatchToProps)(InsideProjectBox);
