/* eslint-disable max-len */

'use strict';

import _ from 'lodash';
import { isDesktopApp } from '@uxpin/shared-components';
import { documentsActions, documentsActionsOnHover } from '../../constants/documents.constants';
import { insideProjectActions } from '../../constants/insideProject.constants';
import { request } from '../../utils/request';
import { refreshActivityFeed } from './activityFeed.actions';
import { startActionOnSearchData } from './search.actions';
import { fetchDocumentsCount } from './insideProject.actions';
import { searchActions } from '../../constants/search.constants';
import { EVENT_PROTOTYPE_REMOVED, EVENT_PROTOTYPE_RENAMED, EVENT_PROTOTYPE_RESTORED } from '../../constants/desktopAppEvents.constants';
import { projectsStatuses } from '../../constants/projects.constants';
import { getMixpanel } from '../../../../shared/mixpanel';
import { saveLastUpdate, shouldForceGenerateCover } from '../../utils/coversHelper';

let statusesIds = [];
let duplicationStatusesRequestTimeout = null;
let conversionStatusTimeout = null;
let generateCoverTimeout = null;

const requestActionOnDocument = (idDocument, actionOnDocument) => ({
  type: documentsActions.REQUEST_ACTION_ON_DOCUMENT,
  idDocument,
  actionOnDocument,
});

export const updateDocumentName = (idDocument, name) => ({
  type: insideProjectActions.UPDATE_DOCUMENT_NAME,
  idDocument,
  name,
});

export const clearDocumentsActions = () => {
  clearTimeout(duplicationStatusesRequestTimeout);
  clearTimeout(conversionStatusTimeout);
  clearTimeout(generateCoverTimeout);
  statusesIds = [];
  return {
    type: documentsActions.CLEAR_DOCUMENTS_ACTIONS,
  };
};

export const stopActionOnDocument = (idDocument) => ({
  type: documentsActions.STOP_ACTION_ON_DOCUMENT,
  idDocument,
});

export const requestCheckingDuplicationStatus = (idDocument) => requestActionOnDocument(idDocument, documentsActionsOnHover.CHECKING_DUPLICATION_STATUS);

const makeDuplicationStatusesRequest = (dispatch) => {
  if (!statusesIds.length) { return; }

  dispatch(startActionOnSearchData());
  request.post('/documents/duplication_status', {
    body: statusesIds,
  })
    .then((data) => {
      data.forEach((document) => {
        if (document.isDuplicated) {
          dispatch(stopActionOnDocument(document.idDocument));
          dispatch({
            type: insideProjectActions.UPDATE_DUPLICATED_DOCUMENT_STATUS,
            idDocument: document.idDocument,
          });

          dispatch({
            type: searchActions.SEARCH_DATA_UPDATE_DUPLICATED_DOCUMENT_STATUS,
            idDocument: document.idDocument,
          });

          _.remove(statusesIds, (id) => id === document.idDocument);

          getMixpanel((mixpanel) => {
            mixpanel.track('prototype_created', {
              document_created_type: 'duplicate',
              prototype_created_library_chosen: 'duplicate',
            });
            mixpanel.people.increment('number_of_prototypes_created');
          });
        }
      });
      duplicationStatusesRequestTimeout = setTimeout(() => makeDuplicationStatusesRequest(dispatch), 5000);
    })
    .catch(() => {
      // TODO HANDLE ERROR
    });
};

export const checkDuplicationStatuses = (ids) => (dispatch) => {
  const duplicatedDocumentsIds = _.union(statusesIds, ids);

  if (duplicatedDocumentsIds.length > statusesIds.length || !_.isEqual(statusesIds, duplicatedDocumentsIds)) {
    statusesIds = duplicatedDocumentsIds;
    clearTimeout(duplicationStatusesRequestTimeout);
    makeDuplicationStatusesRequest(dispatch);
  }
};

export const updateDocumentCovers = (documents) => (dispatch) => {
  request.post('/documents/covers',
    { body: documents.map((doc) => Object.assign({}, doc, { forceGenerate: false })) }).then((result) => {
    const newData = [];
    result.forEach((doc) => {
      if (doc.shot) {
        dispatch({
          type: insideProjectActions.UPDATE_DOCUMENT_COVER,
          idDocument: doc.idDocument,
          cover: doc.shot,
        });
      } else {
        newData.push({
          idDocument: doc.idDocument,
          idMainPage: doc.idMainPage,
          forceGenerate: false,
        });
      }
    });

    if (newData.length) {
      generateCoverTimeout = setTimeout(() => updateDocumentCovers(newData)(dispatch), 10000);
    }
  });
};

export const forceGenerateDocumentCovers = (documents) => (dispatch) => {
  const body = documents.map((doc) => Object.assign({}, doc, {
    forceGenerate: shouldForceGenerateCover(doc.idDocument)
  }));

  request.post('/documents/covers', { body })
    .then(() => {
      generateCoverTimeout = setTimeout(() => updateDocumentCovers(documents)(dispatch), 5000);
      documents.forEach(({ idDocument } ) => { saveLastUpdate(idDocument); })
    });
};

export const duplicateDocument = (idDocument, noIterations  = false) => (dispatch) => {
  const temporaryId = _.uniqueId('duplicated_');
  dispatch({
    type: insideProjectActions.DUPLICATE_DOCUMENT,
    idDocument,
    temporaryId,
  });

  dispatch({
    type: searchActions.SEARCH_DATA_DUPLICATE_DOCUMENT,
    idDocument,
    temporaryId,
  });

  dispatch(startActionOnSearchData());
  request.post(`/documents/${idDocument}/duplicate`, { body: { noIterations}})
    .then((data) => {
      forceGenerateDocumentCovers([_.pick(data, 'idDocument', 'idMainPage')]);
      dispatch({
        type: insideProjectActions.UPDATE_DUPLICATED_DOCUMENT,
        hash: data.hash,
        idDocument: parseInt(data.id, 10),
        documentName: data.name,
        temporaryId,
      });
      dispatch({
        type: searchActions.SEARCH_DATA_UPDATE_DUPLICATED_DOCUMENT,
        hash: data.hash,
        idDocument: parseInt(data.id, 10),
        documentName: data.name,
        temporaryId,
      });
      dispatch(fetchDocumentsCount());
    })
    .catch(() => {
      // TODO handle error
    });
};

export const checkConversionStatus = (documentsIds) => (dispatch) => {
  clearTimeout(conversionStatusTimeout);
  request.post('/documents/conversion_status', { body: documentsIds })
    .then((response) => {
      let areAllDocumentsConverted = true;
      response.forEach((data) => {
        if (data.isConverted || data.progress) {
          dispatch({
            type: insideProjectActions.UPDATE_DOCUMENT_CONVERSION_STATUS,
            idDocument: data.idDocument,
            isConverted: data.isConverted,
            status: data.status,
            progress: data.progress || 0,
            idMainPage: data.idMainPage,
          });
        }

        if (!data.isConverted) {
          areAllDocumentsConverted = false;
        }
      });

      if (!areAllDocumentsConverted) {
        conversionStatusTimeout = setTimeout(() => {
          checkConversionStatus(documentsIds)(dispatch);
        }, 1000);
      }
    })
    .catch(() => {
      // TODO handle error
    });
};

export const removeDocumentPermanently = (idDocument) => (dispatch) => {
  dispatch({
    type: insideProjectActions.REMOVE_DOCUMENT,
    idDocument,
  });
  dispatch(fetchDocumentsCount());
};

export const countTimeToRemoveDocument = (idDocument) => ({
  type: documentsActions.COUNT_TIME_TO_REMOVE_DOCUMENT,
  idDocument,
});

export const createDocumentByUploadFile = (idDocument, files, withoutProgress) => {
  const file = _.first(files);

  return {
    type: insideProjectActions.CREATE_DOCUMENT_BY_UPLOAD_FILE,
    fileName: file.name.substr(0, file.name.lastIndexOf('.')),
    idDocument,
    withoutProgress,
  };
};

export const createDocumentByChromeExtension = (fileId, name, createdAt, port) => {
  return ({
    type: insideProjectActions.CREATE_DOCUMENT_BY_CHROME_EXTENSION,
    fileName: name,
    fileId,
    idDocument: fileId,
    createdAt,
    port,
  });
};

export const startChromeExtensionUpload = (fileId) => ({
  type: insideProjectActions.START_CHROME_EXTENSION_UPLOAD,
  idDocument: fileId,
});

export const updateDocumentAfterChromeExtensionUpload = (fileId, newName, data) => (dispatch) => {
  dispatch({
    type: insideProjectActions.UPDATE_DOCUMENT_AFTER_CHROME_EXTENSION_UPLOAD,
    idDocument: fileId,
    data,
  });

  if (newName) {
    request.post(`/documents/${data.id_project}/name`, {
      body: { name: newName },
    }).then(() => { dispatch(updateDocumentName(parseInt(data.id_project, 10), newName)); });
  }
};

export const updateUploadDocumentProgress = (idDocument, progress) => ({
  type: insideProjectActions.UPDATE_UPLOAD_DOCUMENT_PROGRESS,
  progress,
  idDocument,
});

export const setDocumentError = (idDocument, tryAgain, cancel) => ({
  type: documentsActions.SET_DOCUMENT_ERROR,
  tryAgain,
  cancel,
  idDocument,
});

export const updateData = (idDocument, data) => ({
  type: insideProjectActions.UPDATE_UPLOAD_DOCUMENT_DATA,
  data,
  idDocument,
});

export const updateUploadDocumentData = (idDocument, idProject, fileData, onError) => (dispatch) => {
  request.post('/upload/project', {
    body: {
      fileHash: fileData[0].fileHash,
      fileName: fileData[0].fileName.substr(0, fileData[0].fileName.lastIndexOf('.')),
      idProject,
      filesUploaded: fileData.map((file) => file.fileKey),
    },
  }).then((data) => {
    dispatch(updateUploadDocumentProgress(idDocument, 100));
    dispatch(updateData(idDocument, data));
    refreshActivityFeed(idProject)(dispatch);
    dispatch(fetchDocumentsCount());
  }).catch(() => {
    if (_.isFunction(onError)) {
      onError();
    }
  });
};

export const restoreDocument = (idDocument) => (dispatch) => {
  dispatch(requestActionOnDocument(idDocument, documentsActionsOnHover.RESTORING));
  request.post(`/documents/${idDocument}/restore`)
    .then(() => {
      dispatch(stopActionOnDocument(idDocument));
      if (isDesktopApp) {
        window.dispatchEvent(new CustomEvent(EVENT_PROTOTYPE_RESTORED, {
          detail: idDocument,
        }));
      }
    })
    .catch(() => {
      // TODO handle error
    });
};

export const removeDocument = (idDocument) => (dispatch) => {
  dispatch(requestActionOnDocument(idDocument, documentsActionsOnHover.REMOVING));
  request.delete(`/documents/${idDocument}`)
    .then(() => {
      dispatch({
        type: documentsActions.INIT_REMOVING_COUNTER,
        idDocument,
      });
      dispatch(requestActionOnDocument(idDocument, documentsActionsOnHover.COUNTING));

      if (isDesktopApp) {
        window.dispatchEvent(new CustomEvent(EVENT_PROTOTYPE_REMOVED, {
          detail: idDocument,
        }));
      }
    })
    .catch(() => {
      // TODO handle error
    });
};

export const renameDocument = (idDocument, name) => (dispatch) => {
  dispatch(requestActionOnDocument(idDocument, documentsActionsOnHover.RENAMING));
  dispatch(startActionOnSearchData());
  request.post(`/documents/${idDocument}/name`, {
    body: { name },
  })
    .then((data) => {
      dispatch(stopActionOnDocument(idDocument));
      dispatch(updateDocumentName(idDocument, data.name));
      dispatch({
        type: searchActions.SEARCH_DATA_RENAME_DOCUMENT,
        idDocument: data.idDocument,
        name: data.name,
      });

      if (isDesktopApp) {
        window.dispatchEvent(new CustomEvent(EVENT_PROTOTYPE_RENAMED, {
          detail: { id: idDocument, name },
        }));
      }

      refreshActivityFeed(data.idProject)(dispatch);
    })
    .catch(() => {
      // TODO handle error
    });
};

export const setDocumentPreviewPassword = (idDocument, password) => (dispatch) => {
  request.post(`/documents/${idDocument}/password`, {
    body: {
      password,
    },
  }, true)
    .then(() => {
      dispatch({
        type: insideProjectActions.UPDATE_DOCUMENT_PASSWORD,
        idDocument,
        password,
      });
    });
};

export const moveDocument = (idDocument, idProject) => (dispatch) => {
  dispatch(requestActionOnDocument(idDocument, documentsActionsOnHover.MOVING));
  dispatch(startActionOnSearchData());
  request.post(`/documents/${idDocument}/project`, {
    body: { idProject },
  })
    .then(() => {
      dispatch(stopActionOnDocument(idDocument));
      dispatch(removeDocumentPermanently(idDocument));
      dispatch({
        type: searchActions.SEARCH_DATA_MOVE_DOCUMENT,
        idDocument,
        idProject,
      });
    })
    .catch(() => {
      // TODO handle error
    });
};

export const discardFailedReimport = (idDocument) => (dispatch) => {
  dispatch(requestActionOnDocument(idDocument, documentsActionsOnHover.RESTORING));
  request.post(`/documents/${idDocument}/status`, {
    body: { status: projectsStatuses.ACTIVE },
  })
    .then((result) => {
      dispatch(stopActionOnDocument(idDocument));
      dispatch({
        type: insideProjectActions.UPDATE_FAILED_REIMPORT_STATUS,
        idDocument: result.idDocument,
        status: result.status,
      });
    })
    .catch(() => {
    // TODO handle error
    });
};

export const discardFailedSketchImport = (idDocument) => (dispatch) => {
  dispatch(requestActionOnDocument(idDocument, documentsActionsOnHover.REMOVING));
  request.post(`/documents/${idDocument}/status`, {
    body: { status: projectsStatuses.DELETED },
  })
    .then(() => {
      dispatch(stopActionOnDocument(idDocument));
      dispatch(removeDocumentPermanently(idDocument));
    })
    .catch(() => {
      // TODO handle error
    });
};

export const deleteDocument = (idDocument) => (dispatch) => {
  dispatch(requestActionOnDocument(idDocument, documentsActionsOnHover.REMOVING));
  dispatch({
    type: insideProjectActions.REMOVE_DOCUMENT,
    idDocument,
  });
  request.post(`/documents/${idDocument}/status`, {
    body: { status: projectsStatuses.DELETED },
  })
    .catch(() => {
      // TODO handle error
    });
};

export const cancelConversion = (idDocument) => (dispatch) => {
  dispatch(requestActionOnDocument(idDocument, documentsActionsOnHover.RESTORING));

  return request.post(`/documents/${idDocument}/conversion/cancel`)
    .then(({ status }) => {
      dispatch(stopActionOnDocument(idDocument));
      if (status === projectsStatuses.DELETED) {
        return dispatch(removeDocumentPermanently(idDocument));
      }

      return dispatch(updateData(idDocument, { isConverted: false, status }));
    })
    .catch(console.error);
};

export const cancelDuplication = (idDocument) => (dispatch) => {
  dispatch(requestActionOnDocument(idDocument, documentsActionsOnHover.REMOVING));
  request.post(`/documents/${idDocument}/status`, {
    body: { status: projectsStatuses.DELETED },
  })
    .then(() => {
      dispatch(stopActionOnDocument(idDocument));
      dispatch(removeDocumentPermanently(idDocument));
    })
    .catch(() => {
    // TODO handle error
    });
};

export const fetchFirstProject = (type) => (dispatch) => {
  request.get('/projects/')
    .then((projectsData) => {
      if (!projectsData.projects.length) {
        return null;
      }

      const firstProject = _.find(projectsData.projects, { type });

      if (!firstProject) {
        return null;
      }

      return request.get(`/project/${firstProject.idProject}/documents`)
        .then((data) => {
          dispatch({
            type: documentsActions.FETCH_FIRST_PROJECT,
            data,
          });
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
        });
    })
    .catch((e) => {
      console.log(e); // eslint-disable-line no-console
    });
};
