'use strict';

import { connect } from 'react-redux';
import NewProjectBtnComponent from '../components/ProjectBar/components/NewProjectBtn';
import { projects } from '../../controllers/actions/projects.actions';
import { getActiveProjectsCount } from '../../utils/projects';
import { isAccountParked } from '../../utils/account';

export const mapStateToProps = (state) => {
  const {
    isAdding, isAddingError, currentAddedProjectId, items, errorType,
  } = state.projects;
  const { lastProjectGroupId } = state.projectsGroups; 
  const { permissions } = state.permissions;
  const { accountPlan } = state.accountSettings;
  const { data } = state.currentUser;

  return {
    isAccountParked: isAccountParked(data.idActiveAccount, data.accounts),
    projectsGroups: items,
    isLimitExceeded: !!(!accountPlan || (accountPlan && accountPlan.projects <= getActiveProjectsCount(items))),
    isAdding,
    isAddingError,
    errorType,
    currentAddedProjectId,
    permissions,
    lastProjectGroupId,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  saveProject: (name, idProjectGroup) => {
    dispatch(projects.saveProject(name, idProjectGroup));
  },

  clearAddProjectError: () => {
    dispatch(projects.clearAddProjectError());
  },
});

export const NewProjectBtn = connect(mapStateToProps, mapDispatchToProps)(NewProjectBtnComponent);
