/* eslint-disable max-len */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import config from 'react-global-configuration';
import classnames from 'classnames';
import { noop } from 'lodash';
import autobind from 'autobind-decorator';
import { cloudPositions, GLOBAL_ELEMENTS, triggerTypes } from '@uxpin/shared-components';
import ProjectBoxActionsCloud from '../../../containers/ProjectBoxActionsCloudContainer';

export default class ProjectBoxActionsBottom extends React.Component {
  getCommentsUrl() {
    const { document } = this.props;
    return `${config.get('PREVIEW_URL')}/${document.hash}#/pages/${document.idMainPage}/comment/comments`;
  }

  getUnresolvedCommentsCount() {
    const { commentsCount, document } = this.props;
    return commentsCount[document.hash] ? commentsCount[document.hash].unresolvedComments : 0;
  }

  getUnresolvedCommentsClass() {
    return classnames({ unresolved: !!this.getUnresolvedCommentsCount() });
  }

  getUnresolvedCommentsLabel() {
    const count = this.getUnresolvedCommentsCount();
    if (count) {
      return `${count} unresolved ${(count > 1) ? 'comments' : 'comment'}`;
    }

    return 'Comments';
  }

  renderDesignCritiqueLink() {
    // Not used right now
    // Moved due to JSCS warnings
    return (
      <li className="unresolved">
        <a
          href="#design-critique"
          className="icon-dashboard-design-critique only-icon-font blue-hover"
          onClick={this.props.openModalboxDesignCritique}
          title="Design critique">
          Design critique
        </a>
      </li>
    );
  }

  renderApprovalProcessLink() {
    if (!this.props.canAddEditDeleteApprovals) {
      return null;
    }

    return (
      <li>
        <a
          href="#approval-process"
          className="underline-link gray-link icon-general-approval"
          onClick={this.props.openApprovalProcess}
          title="Approvals">
          Approvals
        </a>
      </li>
    );
  }

  @autobind
  startChromeExtensionUpload(e) {
    e.preventDefault();
    const { document, startChromeExtensionUpload } = this.props;
    startChromeExtensionUpload(document.port, document.fileId);
  }

  @autobind
  discardChromeExtensionUpload(e) {
    e.preventDefault();
    const { document, discardChromeExtensionUpload } = this.props;
    discardChromeExtensionUpload(document.port, document.fileId);
  }

  renderUsabilityTestInfo() {
    const { document } = this.props;
    if (!document.isChromeExtensionUpload) { return; }

    return (
      <li className="usability-info">
        <p>The video is stored locally.</p>
        <p>
          <a onClick={this.startChromeExtensionUpload} href="#upload-to-uxpin" className="underline-link white-link">Upload to UXPin</a>
          &nbsp;
          or
          <a onClick={this.discardChromeExtensionUpload} href="#discard" className="underline-link white-link">discard</a>
        </p>
      </li>
    );
  }

  renderCommentsLink() {
    const { document } = this.props;

    if (document.idMainPage) {
      return (
        <li className={this.getUnresolvedCommentsClass()}>
          <a
            className="underline-link icon-general-comments"
            href={this.getCommentsUrl()}
            title={this.getUnresolvedCommentsLabel()}
            target="_blank">
            Comments
          </a>
        </li>
      );
    }
  }

  render() {
    return (
      <footer className="actions-bottom">
        <ul className={GLOBAL_ELEMENTS.UNORDERED_LIST}>
          {this.renderUsabilityTestInfo()}
          {this.renderApprovalProcessLink()}
          {this.renderCommentsLink()}
          <li className="more-actions-wrapper">
            <ProjectBoxActionsCloud
              defaultPosition={cloudPositions.RIGHT_TOP}
              triggerType={triggerTypes.HOVER}
              offset={this.props.offset}
              onOpen={this.props.openDocumentActionsCloud}
              onClose={this.props.closeDocumentActionsCloud}
              autoClose
              hasAccessToEditor={this.props.hasAccessToEditor}
              getParentNodeEl={this.props.getParentNodeEl}
              features={this.props.features}
              isAccountParked={this.props.isAccountParked}
              duplicateDocument={this.props.duplicateDocument}
              duplicateDocumentWithoutIterations={this.props.duplicateDocumentWithoutIterations}
              projectsList={this.props.projectsList}
              document={this.props.document}
              openModalboxDocumentChangeProject={this.props.openModalboxDocumentChangeProject}
              openModalboxDocumentChangeName={this.props.openModalboxDocumentChangeName}
              openModalboxDocumentDelete={this.props.openModalboxDocumentDelete}
            />
          </li>
        </ul>
      </footer>
    );
  }
}

ProjectBoxActionsBottom.propTypes = {
  commentsCount: PropTypes.object,
  document: PropTypes.object.isRequired,
  getParentNodeEl: PropTypes.func.isRequired,
  duplicateDocument: PropTypes.func.isRequired,
  projectsList: PropTypes.array,
  features: PropTypes.object,
  openModalboxDocumentChangeProject: PropTypes.func.isRequired,
  openModalboxDocumentChangeName: PropTypes.func.isRequired,
  openModalboxDocumentDelete: PropTypes.func.isRequired,
};

ProjectBoxActionsBottom.defaultProps = {
  commentsCount: {},
  document: {},
  getParentNodeEl: noop,
  duplicateDocument: noop,
  projectsList: [],
  features: {},
  openModalboxDocumentChangeProject: noop,
  openModalboxDocumentChangeName: noop,
  openModalboxDocumentDelete: noop,
};
