'use strict';

export default {
  MAIN: '/',
  PROJECTS: 'projects',
  PROJECT: 'project',
  INACTIVE_ACCOUNT: 'inactive-account',
  USER_LOCKED_OUT: 'user-locked-out',
  MODAL: 'modal',
  LIMITS_EXCEEDED: 'limits-exceeded',
  USER_LIMITS: 'user-limits',
  DESIGN_LIMITS_REACHED: 'design-limits-reached',
  DESIGN_SYSTEMS_CREATE: 'design-systems-create',
  DESIGN_SYSTEMS: 'design-systems',
  DESIGN_SYSTEM: 'design-system',
  GET_ADD_ON: 'get-add-on',
  MERGE: 'merge',
  MERGE_LIBRARY_MANAGER: 'merge-component-manager',
  EDITOR: 'edit',
};
