'use strict';

export const accountSettingsActions = {
  REQUEST_REVERT_TO_OLD_DASHBOARD: 'REQUEST_REVERT_TO_OLD_DASHBOARD',
  REVERT_TO_OLD_DASHBOARD: 'REVERT_TO_OLD_DASHBOARD',
  REVERT_TO_OLD_DASHBOARD_ERROR: 'REVERT_TO_OLD_DASHBOARD_ERROR',
  NOT_REVERT_TO_OLD_DASHBOARD: 'NOT_REVERT_TO_OLD_DASHBOARD',
  FETCH_ACCOUNT_SETTINGS: 'FETCH_ACCOUNT_SETTINGS',
  FETCH_ACCOUNT_PLAN: 'FETCH_ACCOUNT_PLAN',
  FETCH_ACCOUNT_PROSPECT_DATA: 'FETCH_ACCOUNT_PROSPECT_DATA',
  TOGGLE_FETCH_STATUS: 'TOGGLE_FETCH_STATUS',
};

export const accountStatuses = {
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  BLOCKED: 'blocked',
  DELETED: 'deleted',
  CANCELED: 'canceled',
  PARKED: 'parked',
};

export const accountPlanNames = {
  STARTER: 'Starter',
  FREELANCER: 'Freelancer',
  TEAM_EFFORT: 'Team Effort',
  COMPANY_WIDE: 'Company-Wide',
  TEAM_EFFORT_ANNUAL: 'Team effort annual',
  PER_SEAT_MONTHLY: 'Per seat monthly',
  PER_SEAT_YEARLY: 'Per seat yearly',
  TEAM_EFFORT_3_MONTHS: 'Team effort 3 months',
  PARKING: 'Parking',
  ANNUAL_DISCOUNT_40: '40% annual discount',
  ANNUAL_DISCOUNT_25: '25% annual discount',
  LAYERS_PROMO_OFFER: 'Layers promo offer',
  BASIC_MONTHLY: 'Basic monthly',
  BASIC_ANNUAL: 'Basic annual',
  PRO_MONTHLY: 'Pro monthly',
  PRO_ANNUAL: 'Pro annual',
  PROPLUS_MONTHLY: 'Pro+ monthly',
  PROPLUS_ANNUAL: 'Pro+ annual',
  ENTERPRISE_MONTHLY: 'Enterprise monthly',
  ENTERPRISE_ANNUAL: 'Enterprise annual',
  ENTERPRISE_TIER_MONTHLY: 'Enterprise Tier monthly',
  ENTERPRISE_TIER_ANNUAL: 'Enterprise Tier annual',
  ENTERPRISE_MERGE_ANNUAL: 'Merge Enterprise Annual',
  ENTERPRISE_MERGE_MONTH: 'Merge Enterprise Monthly',
  TEAM_MONTHLY: 'Team monthly',
  TEAM_ANNUAL: 'Team annual',
  COLLABORATE: 'Collaborate',
  DESIGN_PROTOTYPING: 'Design & Prototyping',
  PROTOTYPING_MONTHLY: 'Prototyping Monthly',
  PROTOTYPING_ANNUAL: 'Prototyping Annual',
  STARTER_MONTHLY: 'Starter Monthly',
  STARTER_ANNUAL: 'Starter Annual',
  ADVANCED_MONTHLY: 'Advanced Monthly',
  ADVANCED_ANNUAL: 'Advanced Annual',
  SYSTEMS_MONTHLY: 'Systems Monthly',
  SYSTEMS_ANNUAL: 'Systems Annual',
  SYSTEM_MONTHLY: 'System Monthly',
  SYSTEM_ANNUAL: 'System Annual',
  SMALL_TEAM_MONTHLY: 'Small Team Monthly',
  SMALL_TEAM_ANNUAL: 'Small Team Annual',
  COMPANY_MONTHLY: 'Company Monthly',
  COMPANY_ANNUAL: 'Company Annual',
  BASIC_MONTHLY_SPEC_MODE: 'Basic monthly Spec Mode',
  BASIC_ANNUAL_SPEC_MODE: 'Basic annual Spec Mode',
  PRO_MONTHLY_SPEC_MODE: 'Pro monthly Spec Mode',
  PRO_ANNUAL_SPEC_MODE: 'Pro annual Spec Mode',
  TEAM_MONTHLY_SPEC_MODE: 'Team monthly Spec Mode',
  TEAM_ANNUAL_SPEC_MODE: 'Team annual Spec Mode',
  PROPLUS_MONTHLY_SPEC_MODE: 'Pro+ monthly Spec Mode',
  PROPLUS_ANNUAL_SPEC_MODE: 'Pro+ annual Spec Mode',
  TEAM_MONTHLY_SPEC_MODE_SPECIAL: 'Team monthly Spec Mode Special',
  TEAM_ANNUAL_SPEC_MODE_SPECIAL: 'Team annual Spec Mode Special',
  TRIAL: 'Trial',
  EDU: 'UXPin Learn',
  FREE: 'Free',
  PROFESSIONAL_MONTHLY: 'Professional Monthly',
  PROFESSIONAL_ANNUAL: 'Professional Annual',
  INDIVIDUAL_MONTHLY: 'Individual Monthly',
  INDIVIDUAL_ANNUAL: 'Individual Annual',
};

export const accountPlanNamesUnique = {
  FREEMIUM_2019: 'freemium_2019',
  PROFESSIONAL_2019Q1_MONTH: 'professional_2019q1_month',
  PROFESSIONAL_2019Q1_YEAR: 'professional_2019q1_year',
  TEAM_2019Q1_MONTH: 'team_2019q1_month',
  TEAM_2019Q1_YEAR: 'team_2019q1_year',
  INDIVIDUAL_2019Q2_MONTH: 'individual_2019q2_month',
  INDIVIDUAL_2019Q2_YEAR: 'individual_2019q2_year',
  SMALL_TEAM_2019Q2_MONTH: 'small_team_2019q2_month',
  SMALL_TEAM_2019Q2_YEAR: 'small_team_2019q2_year',
  Y2020M06_PROFESSIONAL_MONTH: 'y2020m06_professional_month',
  Y2020M06_PROFESSIONAL_YEAR: 'y2020m06_professional_year',
};

export const accountPlanTypesUnique = {
  FREE2019: 'FREE2019',
  PROFESSIONAL2019Q1: 'PROFESSIONAL2019Q1',
  TEAM2019Q1: 'TEAM2019Q1',
  SMALLTEAM2019Q2: 'SMALLTEAM2019Q2',
  INDIVIDUAL2019Q2: 'INDIVIDUAL2019Q2',
  Y2020M06_PROFESSIONAL: 'Y2020M06_PROFESSIONAL',
};

export const accountPlansUnique = {
  [accountPlanTypesUnique.FREE2019]: [
    accountPlanNamesUnique.FREEMIUM_2019,
  ],
  [accountPlanTypesUnique.PROFESSIONAL2019Q1]: [
    accountPlanNamesUnique.PROFESSIONAL_2019Q1_MONTH,
    accountPlanNamesUnique.PROFESSIONAL_2019Q1_YEAR,
  ],
  [accountPlanTypesUnique.TEAM2019Q1]: [
    accountPlanNamesUnique.TEAM_2019Q1_MONTH,
    accountPlanNamesUnique.TEAM_2019Q1_YEAR,
  ],
  [accountPlanTypesUnique.SMALLTEAM2019Q2]: [
    accountPlanNamesUnique.SMALL_TEAM_2019Q2_MONTH,
    accountPlanNamesUnique.SMALL_TEAM_2019Q2_YEAR,
  ],
  [accountPlanTypesUnique.INDIVIDUAL2019Q2]: [
    accountPlanNamesUnique.INDIVIDUAL_2019Q2_MONTH,
    accountPlanNamesUnique.INDIVIDUAL_2019Q2_YEAR,
  ],
  [accountPlanTypesUnique.Y2020M06_PROFESSIONAL]: [
    accountPlanNamesUnique.Y2020M06_PROFESSIONAL_MONTH,
    accountPlanNamesUnique.Y2020M06_PROFESSIONAL_YEAR,
  ],
};

export const excludedFromSpecPromo = [
  accountPlanNames.ENTERPRISE_MONTHLY,
  accountPlanNames.ENTERPRISE_ANNUAL,
  accountPlanNames.ENTERPRISE_TIER_MONTHLY,
  accountPlanNames.ENTERPRISE_TIER_ANNUAL,
];

export const isSpecModePlan = (planName) => !!planName.match(/spec mode/i);

export const sketchPluginPromoSegments = {
  SKETCH_PLUGIN_PROMO_SEGMENT_UPSELL: 'upsell',
  SKETCH_PLUGIN_PROMO_SEGMENT_INFORMATIVE: 'informative',
};

export const specmodeAgainPromoSegments = {
  UPSELL: 'upsell',
  INFORMATIVE: 'informative',
  WINBACK: 'winback',
};

export const designViewsModelPlans = [
  accountPlanNames.COLLABORATE,
  accountPlanNames.DESIGN_PROTOTYPING,
];

export const isDesignViewsModelPlan = (planName) => designViewsModelPlans.indexOf(planName) > -1;

export const accountPlanTypes = {
  TRIAL: 'TRIAL',
  PROTOTYPING: 'PROTOTYPING',
  SYSTEMS: 'SYSTEMS',
  ENTERPRISE: 'ENTERPRISE',
  STARTER: 'STARTER',
  ADVANCED: 'ADVANCED',
  COMPANY: 'COMPANY',
  SMALL_TEAM: 'SMALL_TEAM',
  EDU: 'EDU',
  FREE: 'FREE',
  PROFESSIONAL: 'PROFESSIONAL',
  TEAM: 'TEAM',
  INDIVIDUAL: 'INDIVIDUAL',
};

export const accountPlans = {
  [accountPlanTypes.TRIAL]: [
    accountPlanNames.TRIAL,
  ],
  [accountPlanTypes.PROTOTYPING]: [
    accountPlanNames.PROTOTYPING_MONTHLY,
    accountPlanNames.PROTOTYPING_ANNUAL,
  ],
  [accountPlanTypes.SYSTEMS]: [
    accountPlanNames.SYSTEMS_MONTHLY,
    accountPlanNames.SYSTEMS_ANNUAL,
    accountPlanNames.SYSTEM_MONTHLY,
    accountPlanNames.SYSTEM_ANNUAL,
  ],
  [accountPlanTypes.ENTERPRISE]: [
    accountPlanNames.ENTERPRISE_ANNUAL,
    accountPlanNames.ENTERPRISE_MONTHLY,
    accountPlanNames.ENTERPRISE_TIER_ANNUAL,
    accountPlanNames.ENTERPRISE_TIER_MONTHLY,
    accountPlanNames.ENTERPRISE_MERGE_ANNUAL,
    accountPlanNames.ENTERPRISE_MERGE_MONTH,
  ],
  [accountPlanTypes.STARTER]: [
    accountPlanNames.STARTER_MONTHLY,
    accountPlanNames.STARTER_ANNUAL,
  ],
  [accountPlanTypes.ADVANCED]: [
    accountPlanNames.ADVANCED_MONTHLY,
    accountPlanNames.ADVANCED_ANNUAL,
  ],
  [accountPlanTypes.COMPANY]: [
    accountPlanNames.COMPANY_MONTHLY,
    accountPlanNames.COMPANY_ANNUAL,
  ],
  [accountPlanTypes.SMALL_TEAM]: [
    accountPlanNames.SMALL_TEAM_MONTHLY,
    accountPlanNames.SMALL_TEAM_ANNUAL,
  ],
  [accountPlanTypes.EDU]: [
    accountPlanNames.EDU,
  ],
  [accountPlanTypes.FREE]: [
    accountPlanNames.FREE,
  ],
  [accountPlanTypes.TEAM]: [
    accountPlanNames.TEAM_MONTHLY,
    accountPlanNames.TEAM_ANNUAL,
  ],
  [accountPlanTypes.PROFESSIONAL]: [
    accountPlanNames.PROFESSIONAL_MONTHLY,
    accountPlanNames.PROFESSIONAL_ANNUAL,
  ],
  [accountPlanTypes.INDIVIDUAL]: [
    accountPlanNames.INDIVIDUAL_MONTHLY,
    accountPlanNames.INDIVIDUAL_ANNUAL,
  ],
  [accountPlanTypes.SMALL_TEAM]: [
    accountPlanNames.SMALL_TEAM_MONTHLY,
    accountPlanNames.SMALL_TEAM_ANNUAL,
  ],
};

export const designSystemNotificationSegments = {
  SWITCH_PROTOTYPING: 'SWITCH_PROTOTYPING',
  SWITCH_SYSTEMS: 'SWITCH_SYSTEMS',
  NO_SWITCH: 'NO_SWITCH',
  ENTERPRISE: 'ENTERPRISE',
};

export const designSystemNotificationSegmentsPlans = {
  [designSystemNotificationSegments.SWITCH_PROTOTYPING]: [
    accountPlanNames.BASIC_MONTHLY,
    accountPlanNames.BASIC_ANNUAL,
    accountPlanNames.PRO_MONTHLY,
    accountPlanNames.PRO_ANNUAL,
  ],
  [designSystemNotificationSegments.SWITCH_SYSTEMS]: [
    accountPlanNames.BASIC_MONTHLY_SPEC_MODE,
    accountPlanNames.BASIC_ANNUAL_SPEC_MODE,
    accountPlanNames.PRO_MONTHLY_SPEC_MODE,
    accountPlanNames.PRO_ANNUAL_SPEC_MODE,
    accountPlanNames.TEAM_MONTHLY_SPEC_MODE,
    accountPlanNames.TEAM_ANNUAL_SPEC_MODE,
    accountPlanNames.PROPLUS_MONTHLY_SPEC_MODE,
    accountPlanNames.PROPLUS_ANNUAL_SPEC_MODE,
    accountPlanNames.TEAM_MONTHLY,
    accountPlanNames.TEAM_ANNUAL,
    accountPlanNames.TEAM_MONTHLY_SPEC_MODE_SPECIAL,
    accountPlanNames.TEAM_ANNUAL_SPEC_MODE_SPECIAL,
  ],
  [designSystemNotificationSegments.NO_SWITCH]: [
    accountPlanNames.STARTER,
    accountPlanNames.FREELANCER,
    accountPlanNames.TEAM_EFFORT,
    accountPlanNames.COMPANY_WIDE,
    accountPlanNames.TEAM_EFFORT_ANNUAL,
    accountPlanNames.PER_SEAT_MONTHLY,
    accountPlanNames.PER_SEAT_YEARLY,
    accountPlanNames.TEAM_EFFORT_3_MONTHS,
    accountPlanNames.PARKING,
    accountPlanNames.ANNUAL_DISCOUNT_40,
    accountPlanNames.ANNUAL_DISCOUNT_25,
    accountPlanNames.LAYERS_PROMO_OFFER,
    accountPlanNames.PROPLUS_MONTHLY,
    accountPlanNames.PROPLUS_ANNUAL,
    accountPlanNames.COLLABORATE,
    accountPlanNames.DESIGN_PROTOTYPING,
  ],
  [designSystemNotificationSegments.ENTERPRISE]: [
    accountPlanNames.ENTERPRISE_MONTHLY,
    accountPlanNames.ENTERPRISE_ANNUAL,
    accountPlanNames.ENTERPRISE_TIER_MONTHLY,
    accountPlanNames.ENTERPRISE_TIER_ANNUAL,
  ],
};

export const planPeriods = {
  MONTH: '1 month',
  YEAR: '1 year',
};

export const PRIVACY_POLICY_0519_DATE = '2019-05-15 00:00:00';
export const PRIVACY_POLICY_0620_DATE = '2020-06-16 00:00:00';

export const accountSources = {
  GOOGLE_SIGN_UP: 'gsignup',
};

export const oneUserOnlyPlans = [
  accountPlanNamesUnique.INDIVIDUAL_2019Q2_MONTH,
  accountPlanNamesUnique.INDIVIDUAL_2019Q2_YEAR,
  accountPlanNamesUnique.PROFESSIONAL_2019Q1_MONTH,
  accountPlanNamesUnique.PROFESSIONAL_2019Q1_YEAR,
];

export const isFree = (planName) => planName === accountPlanNames.FREE;

export const datalayerDateFormat = 'en-US';
