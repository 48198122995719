'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { isEmpty, isFunction } from 'lodash';
import OnboardingPassword from './OnboardingPassword';
import { OnboardingIntendedUses } from '../../containers/Onboarding/OnboardingIntendedUses';
import { onboardingSteps } from '../../../constants/onboarding.constants';
import routes from '../../../constants/routes.constants';
import { goTo } from '../../../utils/router';
import OnboardingAddUsers from './OnboardingAddUsers';
import OnboardingAddUsersConfirmation from './OnboardingAddUsersConfirmation';
import {OnboardingTypeformSurvey} from './OnboardingTypeformSurvey';
import { getMixpanel } from '../../../../../shared/mixpanel';

const { PASSWORD, POSITION, ADD_USERS, ADD_USERS_CONFIRMATION, TYPEFORM_SURVEY } = onboardingSteps;

const SIGNUP_SOURCE_MAP = {
  gsignup: 'Google',
  github_signup: 'Github',
};

export default class OnboardingFlowComponent extends React.Component {
  constructor(props) {
    super(props);

    this.stepComponents = [];
    this.breadcrumbs = [];
    this.showTypeformSurvey = false;

    this.steps = {
      [PASSWORD]: this.renderOnboardingPassword,
      [POSITION]: this.renderOnboardingIntendedUses,
      [ADD_USERS]: this.renderOnboardingAddUsers,
      [ADD_USERS_CONFIRMATION]: this.renderOnboardingAddUsersConfirmation,
      [TYPEFORM_SURVEY]: this.renderOnboardingTypeformSurvey,
    };
  }

  componentDidMount() {
    const { onboardingSeen } = this.props;

    if (onboardingSeen) {
      goTo(routes.PROJECTS);
    }

    this.setStepComponents();
    this.addDataToDataLayer();
  }

  componentDidUpdate() {
    this.setStepComponents();
  }

  @autobind
  setStepComponents() {
    const { dataFetched, showPassword } = this.props;

    if (dataFetched && isEmpty(this.stepComponents)) {
      if (showPassword) {
        this.stepComponents = this.steps[PASSWORD];
      } else {
        this.stepComponents = [
          this.steps[POSITION],
          this.steps[ADD_USERS],
          this.steps[ADD_USERS_CONFIRMATION],
          this.steps[TYPEFORM_SURVEY],
        ];
      }

      this.forceUpdate();
    }
  }

  @autobind
  setShowTypeformSurvey(showSurvey){
    this.showTypeformSurvey = showSurvey;
  }

  @autobind
  setNextStep() {
    const { setOnboardingFlowStepAsSeen, currentStep } = this.props;

    setOnboardingFlowStepAsSeen(currentStep + 1);
  }

  @autobind
  setStep(step) {
    const { setOnboardingFlowStepAsSeen } = this.props;

    setOnboardingFlowStepAsSeen(step);
  }

  @autobind
  setPreviousStep() {
    const { setOnboardingFlowStepAsSeen, currentStep } = this.props;

    setOnboardingFlowStepAsSeen(currentStep - 1);
  }

  @autobind
  renderOnboardingPassword() {
    const { setOnboardingFlowAsSeen } = this.props;

    return (
      <OnboardingPassword
        setNextStep={this.setNextStep}
        setOnboardingFlowAsSeen={setOnboardingFlowAsSeen}
      />
    );
  }

  @autobind
  renderOnboardingIntendedUses() {
    return (
      <OnboardingIntendedUses
        setNextStep={this.setNextStep}
        setShowTypeformSurvey={this.setShowTypeformSurvey}
      />
    );
  }

  @autobind
  renderOnboardingAddUsers() {
    const { setOnboardingFlowAsSeen, dispatchAddUsersOnboarding } = this.props;
    return (
      <OnboardingAddUsers
        setNextStep={this.setNextStep}
        setOnboardingFlowAsSeen={this.finishingOnboardingFlow}
        dispatchAddUsersOnboarding={dispatchAddUsersOnboarding}
        sendTrackingEvent={this.sendTrackingEvent}
      />
    );
  }

  @autobind
  finishingOnboardingFlow(setSeen){
    const{setOnboardingFlowAsSeen} = this.props;
    if(this.showTypeformSurvey){
      const typeformStep = this.stepComponents.indexOf(this.steps[TYPEFORM_SURVEY]);
      this.setStep(typeformStep);
    }else{
      setOnboardingFlowAsSeen(setSeen)
    }
  }

  @autobind
  renderOnboardingAddUsersConfirmation() {
    const { addUsersError, addedUsersCount, setOnboardingFlowAsSeen } = this.props;

    return (
      <OnboardingAddUsersConfirmation
        addUsersError={addUsersError}
        addedUsersCount={addedUsersCount}
        setOnboardingFlowAsSeen={this.finishingOnboardingFlow}
        setPreviousStep={this.setPreviousStep}
        sendTrackingEvent={this.sendTrackingEvent}
      />
    );
  }

  @autobind
  renderOnboardingTypeformSurvey() {
    const { currentUserData, setOnboardingFlowAsSeen } = this.props;

    return (
      <OnboardingTypeformSurvey
        showTypeformSurvey={this.showTypeformSurvey}
        setOnboardingFlowAsSeen={setOnboardingFlowAsSeen}
        currentUserData={currentUserData}
      />
    );
  }

  @autobind
  sendTrackingEvent() {
    const { addedUsersCount } = this.props;
    const accountUsersCount = addedUsersCount + 1;
    getMixpanel((mixpanel) => {
      mixpanel.track('onboarding_completed', {
        account_users_count: accountUsersCount,
      });
    });
  }

  renderStep(number) {
    const renderFunction = this.stepComponents[number];
    if (!isFunction(renderFunction)) {
      return null;
    }

    return renderFunction();
  }

  // Add data to the dataLayer for GTM, issue-1990
  addDataToDataLayer() {
    const signupSource = window.marketing.signupSource || 'none';
    const userEmail = window.marketing.email || 'none';
    const translatedSignupSource = SIGNUP_SOURCE_MAP[signupSource] || signupSource;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Signup',
      email: userEmail,
      signupSource: translatedSignupSource,
    });
  }

  render() {
    const { currentStep, dataFetched } = this.props;

    if (!dataFetched) {
      return null;
    }

    return (
      <section id="onboarding-flow">
        {this.renderStep(currentStep)}
      </section>
    );
  }
}

OnboardingFlowComponent.propTypes = {
  setOnboardingFlowStepAsSeen: PropTypes.func.isRequired,
  setOnboardingFlowAsSeen: PropTypes.func.isRequired,
  dispatchAddUsersOnboarding: PropTypes.func.isRequired,
  showPassword: PropTypes.bool.isRequired,
  currentStep: PropTypes.number,
  onboardingSeen: PropTypes.bool,
  dataFetched: PropTypes.bool,
  addUsersError: PropTypes.bool,
  addedUsersCount: PropTypes.number,
};

OnboardingFlowComponent.defaultProps = {
  currentStep: 0,
  onboardingSeen: false,
  dataFetched: false,
  addUsersError: false,
  addedUsersCount: 0,
};
