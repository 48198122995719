/* eslint-disable max-len */
/* global setTimeout, clearTimeout */

import _ from 'lodash';
import { isDesktopApp } from '@uxpin/shared-components';
import * as constants from '../../constants/projects.constants';
import { searchActions } from '../../constants/search.constants';
import { modalsIds } from '../../constants/modal.constants';
import { apiErrors } from '../../constants/errors.constants';
import { updateSettings } from './settings.actions';
import { request } from '../../utils/request';
import { startActionOnSearchData } from './search.actions';
import { initProjectGroups, getNextProjects, setLastProjectGroupID } from './projectsGroups.actions';
import { openModal, closeModal } from './modal.actions';
import { fetchDocumentsCount } from './insideProject.actions';
import { getProjectCountToExpandedGroup, transformProjectsList } from '../helpers/projects.helper';
import {
  initRemovingCounter,
  requestActionOnProject,
  stopActionOnProject,
  setProjectError,
  removeProjectError,
  countTimeToRemoveProject,
} from './projectActions.actions';
import { EVENT_COLLECTION_ARCHIVED, EVENT_COLLECTION_UNARCHIVED } from '../../constants/desktopAppEvents.constants';
import { getMixpanel } from '../../../../shared/mixpanel';

let statusesIds = [];
let duplicationStatusesRequestTimeout = null;

function getProjects(data) {
  return {
    type: constants.projectsActions.FETCH_PROJECTS,
    projects: data,
  };
}

function requestFetchingProjects() {
  return {
    type: constants.projectsActions.REQUEST_FETCHING_PROJECTS,
  };
}

function clearProjectsActions() {
  clearTimeout(duplicationStatusesRequestTimeout);

  return {
    type: constants.projectsActions.CLEAR_PROJECTS_ACTIONS,
  };
}

function clearRemovedProjects(ids) {
  return {
    type: constants.projectsActions.CLEAR_REMOVED_PROJECTS,
    ids,
  };
}

function requestCreatingProject() {
  return {
    type: constants.projectsActions.REQUEST_CREATING_PROJECT,
  };
}

function createProject(data) {
  return {
    type: constants.projectsActions.CREATE_PROJECT,
    data,
  };
}

function clearAddProjectError() {
  return {
    type: constants.projectsActions.CLEAR_ADDING_PROJECT_ERROR,
  };
}

function fetchGroupsExpandedMode(groupsExpandedMode) {
  return {
    type: constants.projectsActions.FETCH_GROUPS_EXPANDED_MODE,
    groupsExpandedMode,
  };
}

function refreshProjects(groupsOrder) {
  return function (dispatch) {
    request.get('/projects/')
      .then((data) => {
        const transformedData = transformProjectsList(data, groupsOrder);
        dispatch(getProjects(transformedData));
        dispatch(setLastProjectGroupID(data.lastProjectGroupId));
      });
  };
}

function fetchProjects(groupsOrder, groupsExpandedMode) {
  return function (dispatch) {
    dispatch(requestFetchingProjects());
    request.get('/projects/')
      .then((data) => {
        const transformedData = transformProjectsList(data, groupsOrder);
        const projectCount = getProjectCountToExpandedGroup(transformedData, groupsExpandedMode) + constants.projectsConstants.PROJECTS_ON_PAGE;

        dispatch(initProjectGroups());
        dispatch(getProjects(transformedData));
        dispatch(setLastProjectGroupID(data.lastProjectGroupId));
        dispatch(fetchGroupsExpandedMode(groupsExpandedMode));
        dispatch(getNextProjects(Math.ceil(projectCount / constants.projectsConstants.PROJECTS_ON_PAGE)));
        dispatch(fetchDocumentsCount());
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
      // TODO handle error
      });
  };
}

function saveProject(name, idProjectGroup) {
  return (dispatch) => {
    dispatch(requestCreatingProject());
    request.post('/project', {
      body: {
        name,
        idProjectGroup,
      },
    })
      .then((data) => {
        dispatch(setLastProjectGroupID(idProjectGroup));
        dispatch(createProject(data));
        getMixpanel((mixpanel) => {
          mixpanel.track('project_created');
          mixpanel.people.increment('number_of_projects_created');
        });
      })
      .catch((e) => {
        e.json().then((errorData) => {
          dispatch({
            type: constants.projectsActions.ADDING_PROJECT_ERROR,
            errorType: errorData.message,
          });
        });
      });
  };
}

function removeProject(idProject) {
  return (dispatch) => {
    dispatch(requestActionOnProject(idProject, constants.projectsActionsOnHover.REMOVING));
    request.delete(`/project/${idProject}`)
      .then(() => {
        dispatch(initRemovingCounter(idProject));
        dispatch(requestActionOnProject(idProject, constants.projectsActionsOnHover.COUNTING));
      })
      .catch(() => {
        dispatch(stopActionOnProject(idProject));
        dispatch(setProjectError(idProject,
          () => dispatch(removeProject(idProject)),
          () => dispatch(removeProjectError(idProject))));
      });
  };
}

function requestCheckingDuplicationStatus(idProject) {
  return requestActionOnProject(idProject, constants.projectsActionsOnHover.CHECKING_DUPLICATION_STATUS);
}

function makeDuplicationStatusesRequest(dispatch) {
  if (!statusesIds.length) { return; }

  dispatch(startActionOnSearchData());
  request.post('/projects/duplication_status', {
    body: statusesIds,
  })
    .then((data) => {
      data.forEach((project) => {
        if (project.isDuplicated) {
          dispatch(stopActionOnProject(project.idProject));
          dispatch({
            type: constants.projectsActions.UPDATE_DUPLICATED_PROJECT_STATUS,
            idProject: project.idProject,
          });

          dispatch({
            type: searchActions.SEARCH_DATA_UPDATE_DUPLICATED_PROJECT_STATUS,
            idProject: project.idProject,
          });

          dispatch(fetchDocumentsCount());

          _.remove(statusesIds, (n) => n === project.idProject);

          getMixpanel((mixpanel) => {
            mixpanel.track('project_created');
            mixpanel.people.increment('number_of_projects_created');
          });
        }
      });
      duplicationStatusesRequestTimeout = setTimeout(() => makeDuplicationStatusesRequest(dispatch), 5000);
    })
    .catch(() => {
      // TODO HANDLE ERROR
    });
}

function checkDuplicationStatuses(ids) {
  return (dispatch) => {
    const duplicatedProjectsIds = _.union(statusesIds, ids);

    if (duplicatedProjectsIds.length > statusesIds.length || !_.isEqual(statusesIds, duplicatedProjectsIds)) {
      statusesIds = duplicatedProjectsIds;
      clearTimeout(duplicationStatusesRequestTimeout);
      makeDuplicationStatusesRequest(dispatch);
    }
  };
}

function removeProjectPermanently(idProject) {
  return (dispatch) => {
    dispatch({
      type: constants.projectsActions.REMOVE_PROJECT_PERMANENTLY,
      idProject,
    });
    dispatch(fetchDocumentsCount());
  };
}

function duplicateProject(idProject) {
  return (dispatch) => {
    const temporaryId = _.uniqueId('duplicated_');
    dispatch({
      type: constants.projectsActions.DUPLICATE_PROJECT,
      idProject,
      temporaryId,
    });

    dispatch({
      type: searchActions.SEARCH_DATA_DUPLICATE_PROJECT,
      idProject,
      temporaryId,
    });

    dispatch(startActionOnSearchData());
    request.post(`/project/${idProject}/duplicate`)
      .then((data) => {
        dispatch({
          type: constants.projectsActions.UPDATE_DUPLICATED_PROJECT,
          idProject: data.idProject,
          projectName: data.name,
          temporaryId,
        });

        dispatch({
          type: searchActions.SEARCH_DATA_UPDATE_DUPLICATED_PROJECT,
          idProject: data.idProject,
          projectName: data.name,
          temporaryId,
        });
      })
      .catch((e) => {
        e.json().then((errorData) => {
          switch (errorData.message) {
            case apiErrors.LIMIT_EXCEEDED:
              dispatch(removeProjectPermanently(temporaryId));
              dispatch(openModal(modalsIds.MODALBOX_GENERAL_MESSAGE, {
                title: 'This account reached its project limit.',
                onAccept: () => dispatch(closeModal(modalsIds.MODALBOX_GENERAL_MESSAGE)),
                onClose: () => dispatch(closeModal(modalsIds.MODALBOX_GENERAL_MESSAGE)),
              }));
              return;
            default:
              dispatch(setProjectError(temporaryId,
                () => {
                  dispatch(removeProjectPermanently(temporaryId));
                  duplicateProject(idProject);
                },

                () => dispatch(removeProjectPermanently(temporaryId))));
          }
        });
      });
  };
}

function restoreProject(idProject, isArchived) {
  return (dispatch) => {
    dispatch(requestActionOnProject(idProject, constants.projectsActionsOnHover.RESTORING));
    request.post(`/project/${idProject}/restore`, {
      body: { isArchived },
    })
      .then(() => {
        dispatch(stopActionOnProject(idProject));
      })
      .catch(() => {
        dispatch(stopActionOnProject(idProject));
        dispatch(setProjectError(idProject,
          () => dispatch(restoreProject(idProject)),
          () => {
            dispatch(removeProjectError(idProject));
            dispatch(initRemovingCounter(idProject));
            dispatch(requestActionOnProject(idProject, constants.projectsActionsOnHover.COUNTING));
          }));
      });
  };
}

function onChangeGroup(dispatch, idProject, idProjectGroup = null, groupColor) {
  dispatch({
    type: constants.projectsActions.STOP_CHECKING_POSSIBILITY_OF_CHANGING_GROUP,
    showModal: false,
  });
  dispatch(requestActionOnProject(idProject, constants.projectsActionsOnHover.MOVING));
  dispatch(startActionOnSearchData());
  request.put('/project_groups/project', {
    body: [{
      idProject,
      idProjectGroup,
    }],
  }).then(() => {
    if (idProjectGroup !== null) {
      dispatch(setLastProjectGroupID(idProjectGroup));
    }

    dispatch(stopActionOnProject(idProject));
    dispatch({
      type: constants.projectsActions.CHANGE_GROUP,
      ids: [idProject],
      idProjectGroup,
    });

    dispatch({
      type: searchActions.SEARCH_DATA_CHANGE_GROUP,
      idProject,
      idProjectGroup,
      groupColor,
      hasProjectGroup: true,
    });

  }).catch(() => {
    dispatch(stopActionOnProject(idProject));
    dispatch(setProjectError(idProject,
      () => onChangeGroup(dispatch, idProject, idProjectGroup),
      () => dispatch(removeProjectError(idProject))));
  });
}

function stopCheckingPossibilityOfChangingGroup(showModal, isUserToAdd, hasProjectGroup, alertActions, projectGroupName) {
  return {
    type: constants.projectsActions.STOP_CHECKING_POSSIBILITY_OF_CHANGING_GROUP,
    showModal,
    alertActions,
    projectGroupName,
    isUserToAdd,
    hasProjectGroup,
  };
}

function changeProjectsGroup(projectsIds, data) {
  return (dispatch) => {
    projectsIds.forEach((id) => {
      dispatch(requestActionOnProject(id, constants.projectsActionsOnHover.MOVING));
    });

    request.put('/project_groups/project', {
      body: data,
    }).then(() => {
      projectsIds.forEach((id) => {
        dispatch(stopActionOnProject(id, constants.projectsActionsOnHover.MOVING));
      });
    });
  };
}

function changeGroup(idProject, idProjectGroup, hasProjectGroup, groupColor, forceChangingGroup) {
  return (dispatch) => {
    if (!forceChangingGroup) {
      const promises = [];
      promises.push(request.get('/project_groups/access'));
      promises.push(request.get(`/project/${idProject}/members`));

      dispatch({
        type: constants.projectsActions.START_CHECKING_POSSIBILITY_OF_CHANGING_GROUP,
      });

      Promise.all(promises)
        .then((data) => {
          const groupUsers = _.find(data[0], (group) => group.idProjectGroup === idProjectGroup).userIds;
          const projectUsers = data[1];
          const isUserToAdd = !!_.difference(groupUsers, projectUsers).length;

          if (isUserToAdd || hasProjectGroup) {
            dispatch({
              type: constants.projectsActions.STOP_CHECKING_POSSIBILITY_OF_CHANGING_GROUP,
              showModal: true,
              isUserToAdd,
              hasProjectGroup,
            });
          } else {
            onChangeGroup(dispatch, idProject, idProjectGroup, groupColor);
          }
        })
        .catch(() => {
          dispatch(stopCheckingPossibilityOfChangingGroup(false, false, false, null, ''));
          dispatch(stopActionOnProject(idProject));
          dispatch(setProjectError(idProject,
            () => dispatch(changeGroup(idProject, idProjectGroup, hasProjectGroup, groupColor, forceChangingGroup)),
            () => dispatch(removeProjectError(idProject))));
        });
    } else {
      onChangeGroup(dispatch, idProject, idProjectGroup, groupColor);
    }
  };
}

function removeProjectFromGroup(idProject, withoutChangeAction, oldProjectGroupId = null) {
  return (dispatch) => {
    dispatch(requestActionOnProject(idProject, constants.projectsActionsOnHover.MOVING));
    dispatch(startActionOnSearchData());
    request.delete('/project_groups/project', {
      body: [{
        idProject,
      }],
    }, true).then(() => {
      dispatch(stopActionOnProject(idProject));
      if (!withoutChangeAction) {
        dispatch({
          type: constants.projectsActions.CHANGE_GROUP,
          ids: [idProject],
          idProjectGroup: null,
        });

        dispatch({
          type: searchActions.SEARCH_DATA_CHANGE_GROUP,
          idProject,
          idProjectGroup: null,
          groupColor: null,
          hasProjectGroup: false,
        });
      }
    }).catch(() => {
      dispatch(stopActionOnProject(idProject));
      dispatch(setProjectError(idProject,
        () => dispatch(removeProjectFromGroup(idProject, withoutChangeAction, oldProjectGroupId)),
        () => {
          dispatch(removeProjectError(idProject));
          if (withoutChangeAction) {
            dispatch({
              type: constants.projectsActions.CHANGE_GROUP,
              ids: [idProject],
              idProjectGroup: oldProjectGroupId,
            });
          }
        }));
    });
  };
}

function removeProjectsFromGroup(projectsIds, projectGroupsIds) {
  return (dispatch) => {
    projectsIds.forEach((id) => {
      dispatch(requestActionOnProject(id, constants.projectsActionsOnHover.MOVING));
    });

    request.delete('/project_groups/project', {
      body: projectsIds.map((idProject) => ({
        idProject,
      })),
    }, true).then(() => {
      projectsIds.forEach((id) => {
        dispatch(stopActionOnProject(id));
      });
    }).catch(() => {
      projectsIds.forEach((id, index) => {
        dispatch(stopActionOnProject(id));
        dispatch(setProjectError(id,
          () => dispatch(removeProjectFromGroup(id, true, projectGroupsIds[index])),
          () => {
            dispatch(removeProjectError(id));
            dispatch({
              type: constants.projectsActions.CHANGE_GROUP,
              ids: [id],
              idProjectGroup: projectGroupsIds[index],
            });
          }));
      });
    });
  };
}

function removeProjectFromRecentlyOpened(projectIds) {
  return request.delete('/settings/recentProjects', {
    body: {
      collectionIds: projectIds,
    },
  });
}

function archiveProject(idProject, withoutArchiveAction, idProjectGroup) {
  return (dispatch) => {
    dispatch(requestActionOnProject(idProject, constants.projectsActionsOnHover.ARCHIVING));
    dispatch(startActionOnSearchData());
    request.post(`/project/${idProject}/archive`)
      .then(() => removeProjectFromRecentlyOpened([idProject]))
      .then(() => {
        if (isDesktopApp) {
          window.dispatchEvent(new CustomEvent(EVENT_COLLECTION_ARCHIVED, { detail: idProject }));
        }

        dispatch(stopActionOnProject(idProject));
        dispatch(fetchDocumentsCount());
        if (!withoutArchiveAction) {
          dispatch({
            type: constants.projectsActions.ARCHIVE_PROJECT,
            ids: [idProject],
          });

          dispatch({
            type: searchActions.SEARCH_DATA_REMOVE_PROJECT,
            idProject,
          });

          getMixpanel((mixpanel) => {
            mixpanel.track('project_archived');
          });
        } else {
          getMixpanel((mixpanel) => {
            mixpanel.track('project_archived');
          });
        }
      })
      .catch(() => {
        dispatch(stopActionOnProject(idProject));
        dispatch(setProjectError(idProject,
          () => dispatch(archiveProject(idProject, withoutArchiveAction)),
          () => {
            dispatch(removeProjectError(idProject));
            if (withoutArchiveAction) {
              dispatch({
                type: constants.projectsActions.UNARCHIVE_PROJECT,
                ids: [idProject],
                idProjectGroup,
              });
            }
          }));
      });
  };
}

function archiveProjects(projectsIds, projectsGroupsIds) {
  return (dispatch) => {
    projectsIds.forEach((id) => {
      dispatch(requestActionOnProject(id, constants.projectsActionsOnHover.ARCHIVING));
    });

    request.post('/projects/archive', {
      body: projectsIds,
    })
      .then(() => removeProjectFromRecentlyOpened(projectsIds))
      .then(() => {
        projectsIds.forEach((id) => {
          if (isDesktopApp) {
            window.dispatchEvent(new CustomEvent(EVENT_COLLECTION_ARCHIVED, { detail: id }));
          }

          dispatch(stopActionOnProject(id));
        });
        dispatch(fetchDocumentsCount());
      }).catch(() => {
        projectsIds.forEach((id, index) => {
          dispatch(stopActionOnProject(id));
          dispatch(setProjectError(id,
            () => archiveProject(id, true, projectsGroupsIds[index])(dispatch),
            () => {
              dispatch(removeProjectError(id));
              dispatch({
                type: constants.projectsActions.UNARCHIVE_PROJECT,
                ids: [id],
                idProjectGroup: projectsGroupsIds[index],
              });
            }));
        });
      });
  };
}

function unarchiveProject(idProject, idProjectGroup) {
  return (dispatch) => {
    dispatch(requestActionOnProject(idProject, constants.projectsActionsOnHover.UNARCHIVING));
    request.post(`/project/${idProject}/unarchive`)
      .then(() => {
        if (isDesktopApp) {
          window.dispatchEvent(new CustomEvent(EVENT_COLLECTION_UNARCHIVED, { detail: idProject }));
        }
        dispatch(stopActionOnProject(idProject));
        dispatch({
          type: constants.projectsActions.UNARCHIVE_PROJECT,
          ids: [idProject],
          idProjectGroup,
        });
        dispatch(fetchDocumentsCount());

        getMixpanel((mixpanel) => {
          mixpanel.track('project_unarchived');
        });
      })
      .catch(() => {
        dispatch(stopActionOnProject(idProject));
        dispatch(setProjectError(idProject,
          () => dispatch(unarchiveProject(idProject, idProjectGroup)),
          () => {
            dispatch(removeProjectError(idProject));
            dispatch({
              type: constants.projectsActions.ARCHIVE_PROJECT,
              ids: [idProject],
            });
          }));
      });
  };
}

function unarchiveAndRemoveProjectFromGroup(idProject, withoutUnarchiveAction) {
  return (dispatch) => {
    dispatch(requestActionOnProject(idProject, constants.projectsActionsOnHover.UNARCHIVING));
    request.delete('/project_groups/project', {
      body: [{
        idProject,
      }],
    }, true).then(() => request.post(`/project/${idProject}/unarchive`))
      .then(() => {
        if (isDesktopApp) {
          window.dispatchEvent(new CustomEvent(EVENT_COLLECTION_UNARCHIVED, { detail: idProject }));
        }
        dispatch(stopActionOnProject(idProject));
        dispatch(fetchDocumentsCount());

        if (!withoutUnarchiveAction) {
          dispatch({
            type: constants.projectsActions.UNARCHIVE_PROJECT,
            ids: [idProject],
            idProjectGroup: null,
          });

          getMixpanel((mixpanel) => {
            mixpanel.track('project_unarchived');
          });
        } else {
          getMixpanel((mixpanel) => {
            mixpanel.track('project_unarchived');
          });
        }
      }).catch(() => {
        dispatch(stopActionOnProject(idProject));
        dispatch(setProjectError(idProject,
          () => dispatch(unarchiveAndRemoveProjectFromGroup(idProject, withoutUnarchiveAction)),
          () => {
            dispatch(removeProjectError(idProject));
            if (withoutUnarchiveAction) {
              dispatch({
                type: constants.projectsActions.ARCHIVE_PROJECT,
                ids: [idProject],
              });
            }
          }));
      });
  };
}

function unarchiveProjectsAndRemoveFromGroup(projectsIds) {
  return (dispatch) => {
    projectsIds.forEach((id) => {
      dispatch(requestActionOnProject(id, constants.projectsActionsOnHover.UNARCHIVING));
    });

    return request.post('/projects/unarchive', {
      body: projectsIds,
    })
      .then(() => request.delete('/project_groups/project', {
        body: projectsIds.map((idProject) => ({
          idProject,
        })),
      }))
      .then(() => {
        projectsIds.forEach((id) => {
          if (isDesktopApp) {
            window.dispatchEvent(new CustomEvent(EVENT_COLLECTION_UNARCHIVED, { detail: id }));
          }

          dispatch(stopActionOnProject(id));
        });
        dispatch(fetchDocumentsCount());
      }).catch(() => {
        projectsIds.forEach((id) => {
          dispatch(stopActionOnProject(id));
          dispatch(setProjectError(id,
            () => dispatch(unarchiveAndRemoveProjectFromGroup(id, true)),
            () => {
              dispatch(removeProjectError(id));
              dispatch({
                type: constants.projectsActions.ARCHIVE_PROJECT,
                ids: [id],
              });
            }));
        });
      });
  };
}

function unarchiveAndChangeGroup(idProject, idProjectGroup, withoutUnarchiveAction) {
  return (dispatch) => {
    dispatch(requestActionOnProject(idProject, constants.projectsActionsOnHover.UNARCHIVING));

    request.post(`/project/${idProject}/unarchive`)
      .then(() => request.put('/project_groups/project', {
        body: [{
          idProject,
          idProjectGroup,
        }],
      }))
      .then(() => {
        if (isDesktopApp) {
          window.dispatchEvent(new CustomEvent(EVENT_COLLECTION_UNARCHIVED, { detail: idProject }));
        }

        dispatch(stopActionOnProject(idProject));
        dispatch(fetchDocumentsCount());

        if (!withoutUnarchiveAction) {
          dispatch({
            type: constants.projectsActions.UNARCHIVE_PROJECT,
            ids: [idProject],
            idProjectGroup,
          });

          getMixpanel((mixpanel) => {
            mixpanel.track('project_unarchived');
          });
        } else {
          getMixpanel((mixpanel) => {
            mixpanel.track('project_unarchived');
          });
        }
      }).catch(() => {
        dispatch(stopActionOnProject(idProject));
        dispatch(setProjectError(idProject,
          () => dispatch(unarchiveAndChangeGroup(idProject, idProjectGroup, withoutUnarchiveAction)),
          () => {
            dispatch(removeProjectError(idProject));
            if (withoutUnarchiveAction) {
              dispatch({
                type: constants.projectsActions.ARCHIVE_PROJECT,
                ids: [idProject],
              });
            }
          }));
      });
  };
}

function unarchiveProjectsAndChangeGroup(projectsIds, projectsGroupsIds, data) {
  return (dispatch) => {
    projectsIds.forEach((id) => {
      dispatch(requestActionOnProject(id, constants.projectsActionsOnHover.UNARCHIVING));
    });

    return request.post('/projects/unarchive', {
      body: projectsIds,
    })
      .then(() => request.put('/project_groups/project', {
        body: data,
      }))
      .then(() => {
        projectsIds.forEach((id) => {
          if (isDesktopApp) {
            window.dispatchEvent(new CustomEvent(EVENT_COLLECTION_UNARCHIVED, { detail: id }));
          }

          dispatch(stopActionOnProject(id));
        });
        dispatch(fetchDocumentsCount());
      }).catch(() => {
        projectsIds.forEach((id, index) => {
          dispatch(stopActionOnProject(id));
          dispatch(setProjectError(id,
            () => dispatch(unarchiveAndChangeGroup(id, projectsGroupsIds[index], true)),
            () => {
              dispatch(removeProjectError(id));
              dispatch({
                type: constants.projectsActions.ARCHIVE_PROJECT,
                ids: [id],
              });
            }));
        });
      });
  };
}

function renameProject(idProject, name) {
  return (dispatch) => {
    dispatch(requestActionOnProject(idProject, constants.projectsActionsOnHover.RENAMING));
    dispatch(startActionOnSearchData());
    request.post(`/project/${idProject}/rename`, {
      body: { name },
    })
      .then((data) => {
        dispatch(stopActionOnProject(idProject));
        dispatch({
          type: constants.projectsActions.RENAME_PROJECT,
          idProject,
          data,
        });
        dispatch({
          type: searchActions.SEARCH_DATA_RENAME_PROJECT,
          idProject,
          name,
        });
      })
      .catch(() => {
        dispatch(stopActionOnProject(idProject));
        dispatch(setProjectError(idProject,
          () => dispatch(renameProject(idProject, name)),
          () => dispatch(removeProjectError(idProject))));
      });
  };
}

function createStorybookOnboardingProject() {
    request.post('/project/first', {
      body: {
        projectType: 'TRY_STORYBOOK',
        projectName: 'Storybook integration',
      },
    }).then((data) => {
      const { url } = data;
      if (url) {
        window.location.href = url;
      }
    });
}

function setSortType(sortType) {
  return (dispatch) => updateSettings('projectSortingType', sortType, false)(dispatch);
}

export const projects = {
  fetchProjects,
  clearRemovedProjects,
  clearProjectsActions,
  refreshProjects,
  createProject,
  saveProject,
  removeProject,
  changeGroup,
  changeProjectsGroup,
  unarchiveAndChangeGroup,
  removeProjectFromGroup,
  removeProjectsFromGroup,
  archiveProject,
  archiveProjects,
  restoreProject,
  unarchiveProject,
  unarchiveProjectsAndChangeGroup,
  unarchiveAndRemoveProjectFromGroup,
  unarchiveProjectsAndRemoveFromGroup,
  duplicateProject,
  countTimeToRemoveProject,
  clearAddProjectError,
  setSortType,
  getProjectCountToExpandedGroup,
  requestCheckingDuplicationStatus,
  checkDuplicationStatuses,
  renameProject,
  removeProjectPermanently,
  stopCheckingPossibilityOfChangingGroup,
  createStorybookOnboardingProject,
};
