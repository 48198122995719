'use strict';

export const projectsConstants = {
  PROJECTS_ON_PAGE: 30,
  // eslint-disable-next-line max-len
  GROUPS_COLORS: ['c5eff7', '913d88', 'e26a6a', 'aea8d3', 'a2ded0', '03c9a9', 'c8f7c5', 'e87e04', 'f89406', 'f9bf3b', 'bdc3c7', '3fc380', '1f3a93', '663399', 'f64747', '34495e', '26a65b', 'bfbfbf'],
  DEFAULT_COLOR: '03c9a9',
};

export const projectsSortingTypes = {
  NAME_ASC: 'name_asc',
  NAME_DESC: 'name_desc',
  CREATE_DATE_ASC: 'create_date_asc',
  CREATE_DATE_DESC: 'create_date_desc',
  UPDATE_DATE_ASC: 'update_date_asc',
  UPDATE_DATE_DESC: 'update_date_desc',
};

export const progressStatuses = {
  IN_PROGRESS: 1,
  WAITING_FOR_REVIEW: 2,
  ACCEPTED: 3,
  PAUSED: 4,
  REJECTED: 5,
};

export const projectStatuses = [
  { idStatus: progressStatuses.IN_PROGRESS, name: 'In progress' },
  { idStatus: progressStatuses.WAITING_FOR_REVIEW, name: 'Waiting for review' },
  { idStatus: progressStatuses.ACCEPTED, name: 'Accepted' },
  { idStatus: progressStatuses.PAUSED, name: 'Paused' },
];

export const statusIdToClassMap = (function () {
  const map = {};
  map[progressStatuses.WAITING_FOR_REVIEW] = 'waiting';
  map[progressStatuses.IN_PROGRESS] = 'progress';
  map[progressStatuses.ACCEPTED] = 'approved';
  map[progressStatuses.PAUSED] = 'paused';
  map[progressStatuses.REJECTED] = 'rejected';

  return map;
}());

export const projectsStatuses = {
  ACTIVE: 0,
  DELETED: 2,
  ARCHIVED: 3,
  HIDDEN: 4,
  DUPLICATED: 5,
  ARCHIVED_DUPLICATED: 6,
  REIMPORT_FAILED: 7,
  IMPORT_OBSOLETE_FILE: 8,
  IMPORT_NO_ARTBOARD: 9,
  IMPORT_FAILED: 10,
  UXPFILE_IMPORT_FAILED: 11,
};

export const projectsActionsOnHover = {
  CHECKING_DUPLICATION_STATUS: 'duplication-status',
  REMOVING: 'removing',
  RENAMING: 'renaming',
  ARCHIVING: 'archiving',
  UNARCHIVING: 'unarchiving',
  COUNTING: 'removing-counter',
  RESTORING: 'restoring',
  DUPLICATING: 'duplicating',
  MOVING: 'MOVING',
};

export const projectsActions = {
  CREATE_PROJECT: 'CREATE_PROJECT',
  RECEIVE_PROJECTS: 'RECEIVE_PROJECTS',
  CLEAR_PROJECTS_LIST: 'CLEAR_PROJECTS_LIST',
  FETCH_PROJECTS: 'FETCH_PROJECTS',
  NEXT_PROJECTS: 'NEXT_PROJECTS',
  INIT_REMOVING_COUNTER: 'INIT_REMOVING_COUNTER',
  CLEAR_PROJECTS_ACTIONS: 'CLEAR_PROJECTS_ACTIONS',
  COUNT_TIME_TO_REMOVE_PROJECT: 'COUNT_TIME_TO_REMOVE_PROJECT',
  SET_PROJECT_ERROR: 'SET_PROJECT_ERROR',
  REMOVE_PROJECT_ERROR: 'REMOVE_PROJECT_ERROR',
  REQUEST_ACTION_ON_PROJECT: 'REQUEST_ACTION_ON_PROJECT',
  REMOVE_PROJECT_PERMANENTLY: 'REMOVE_PROJECT_PERMANENTLY',
  UPDATE_PROJECT_STATUS: 'UPDATE_PROJECT_STATUS',
  UPDATE_DUPLICATED_PROJECT_STATUS: 'UPDATE_DUPLICATED_PROJECT_STATUS',
  ARCHIVE_PROJECT: 'ARCHIVE_PROJECT',
  CHANGE_GROUP: 'CHANGE_GROUP',
  STOP_ACTION_ON_PROJECT: 'STOP_ACTION_ON_PROJECT',
  INIT_PROJECTS_GROUPS: 'INIT_PROJECTS_GROUPS',
  CLEAR_REMOVED_PROJECTS: 'CLEAR_REMOVED_PROJECTS',
  DUPLICATE_PROJECT: 'DUPLICATE_PROJECT',
  UNARCHIVE_PROJECT: 'UNARCHIVE_PROJECT',
  UPDATE_DUPLICATED_PROJECT: 'UPDATE_DUPLICATED_PROJECT',
  RENAME_PROJECT: 'RENAME_PROJECT',
  SCROLL_PROJECTS_LIST: 'SCROLL_PROJECTS_LIST',
  COLLAPSE_PROJECTS_GROUP: 'COLLAPSE_PROJECTS_GROUP',
  EXPAND_PROJECTS_GROUP: 'EXPAND_PROJECTS_GROUP',
  REQUEST_FETCHING_PROJECTS: 'REQUEST_FETCHING_PROJECTS',
  ADDING_PROJECT_ERROR: 'ADDING_PROJECT_ERROR',
  CLEAR_ADDING_PROJECT_ERROR: 'CLEAR_ADDING_PROJECT_ERROR',
  SET_SORT_TYPE: 'SET_SORT_TYPE',
  REQUEST_CREATING_PROJECT: 'REQUEST_CREATING_PROJECT',
  SET_GROUPS_ORDER: 'SET_GROUPS_ORDER',
  FETCH_GROUPS_EXPANDED_MODE: 'FETCH_GROUPS_EXPANDED_MODE',
  START_CHECKING_POSSIBILITY_OF_CHANGING_GROUP: 'START_CHECKING_POSSIBILITY_OF_CHANGING_GROUP',
  STOP_CHECKING_POSSIBILITY_OF_CHANGING_GROUP: 'STOP_CHECKING_POSSIBILITY_OF_CHANGING_GROUP',
  FETCH_WELCOME_MODAL_VISIBILITY: 'FETCH_WELCOME_MODAL_VISIBILITY',
  PRODUCT_NEWS_UPDATE_SEEN: 'PRODUCT_NEWS_UPDATE_SEEN',
  MODALBOX_FOR_PAYING_USERS_VERSION_SEEN: 'MODALBOX_FOR_PAYING_USERS_VERSION_SEEN',
  MODALBOX_FOR_TRIAL_USERS_VERSION_SEEN: 'MODALBOX_FOR_TRIAL_USERS_VERSION_SEEN',
  MODALBOX_SKETCH_PLUGIN_PROMO_VERSION_SEEN: 'MODALBOX_SKETCH_PLUGIN_PROMO_VERSION_SEEN',
  FETCH_BILLING_SYSTEM_CHANGE_MODAL_VISIBILITY: 'FETCH_BILLING_SYSTEM_CHANGE_MODAL_VISIBILITY',
  MODALBOX_FREEMIUM_TEST_INTRO_SEEN: 'MODALBOX_FREEMIUM_TEST_INTRO_SEEN',
  CREATE_STORYBOOK_ONBOARDING_PROJECT: 'CREATE_STORYBOOK_ONBOARDING_PROJECT',
  SET_LAST_PROJECT_GROUP_ID: 'SET_LAST_PROJECT_GROUP_ID',
};

export const badgeLabels = {
  pending: 'pending',
  rejected: 'rejected',
  approved: 'approved',
  inactive: 'inactive',
};

export const types = {
  DISCOVER_ONBOARDING_COLLECTION: 'DISCOVER_ONBOARDING_COLLECTION',
  EDU_PATH_ONBOARDING_COLLECTION: 'EDU_PATH_ONBOARDING_COLLECTION',
};

const DEFAULT_SKETCH_MESSAGE = 'Sorry, this import was unsuccessful.';

const SKETCH_MESSAGES = {
  OBSOLETE: 'You can only import files saved in Sketch 50 or later.',
  NO_ARTBOARD: 'You can only import files that have artboards.',
  IMPORT_FAILED: DEFAULT_SKETCH_MESSAGE,
};

export const ERROR_TYPES = {
  COMMON: {
    className: 'common-error',
    message: 'Sorry, something went wrong',
    tryAgain: true,
  },
  REIMPORT: {
    className: 'failed-message',
    message: 'Import was unsuccessful. Please try exporting your project from Sketch again.',
    tryAgain: false,
  },
  SKETCH_IMPORT: {
    className: 'sketch-import-message',
    message: DEFAULT_SKETCH_MESSAGE,
    tryAgain: false,
  },
  UXPFILE_IMPORT: {
    className: 'uxpfile-import-message',
    message: 'File not supported.',
    tryAgain: false,
  },
};

export const DOCUMENT_STATUS_TO_MSG_MAP = {
  [projectsStatuses.IMPORT_NO_ARTBOARD]: SKETCH_MESSAGES.NO_ARTBOARD,
  [projectsStatuses.IMPORT_OBSOLETE_FILE]: SKETCH_MESSAGES.OBSOLETE,
  [projectsStatuses.IMPORT_FAILED]: SKETCH_MESSAGES.IMPORT_FAILED,
};
