'use strict';

import { projectsActions } from '../../constants/projects.constants';

export function projectsGroupsReducer(state = {
  page: 1,
  collapsedGroups: [],
  groupsOrder: {},
  lastProjectGroupId: null,
}, action) {
  const index = state.collapsedGroups.indexOf(action.expandedGroup);

  switch (action.type) {
    case projectsActions.INIT_PROJECTS_GROUPS:
      return Object.assign({}, state, {
        page: 1,
        collapsedGroups: [],
      });
    case projectsActions.SET_GROUPS_ORDER:
      return Object.assign({}, state, {
        groupsOrder: action.groupsOrder,
      });
    case projectsActions.NEXT_PROJECTS:
      return Object.assign({}, state, {
        page: action.currentPage,
      });
    case projectsActions.SET_LAST_PROJECT_GROUP_ID:
      return Object.assign({}, state, {
        lastProjectGroupId: action.lastProjectGroupId,
      });
    case projectsActions.COLLAPSE_PROJECTS_GROUP:
      return Object.assign({}, state, {
        page: action.page,
        collapsedGroups: [...state.collapsedGroups, action.collapsedGroup],
      });
    case projectsActions.EXPAND_PROJECTS_GROUP:
      return Object.assign({}, state, {
        page: action.page,
        collapsedGroups: [
          ...state.collapsedGroups.slice(0, index),
          ...state.collapsedGroups.slice(index + 1),
        ],
      });
    default:
      return state;
  }
}
