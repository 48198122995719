/* eslint-disable max-len */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */

'use strict';

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import config from 'react-global-configuration';
import { cloudPositions, triggerTypes } from '@uxpin/shared-components';
import { DocumentHeader } from '../../../containers/DocumentHeader';
import ProjectBoxActionsTop from './ProjectBoxActionsTop';
import ProjectBoxActionsCenter from './ProjectBoxActionsCenter';
import ProjectBoxActionsBottom from './ProjectBoxActionsBottom';
import ProjectBoxPending from './ProjectBoxPending';
import { documentsActionsOnHover } from '../../../../constants/documents.constants';
import { projectsStatuses } from '../../../../constants/projects.constants';
import { getEditUrl } from '../../../../utils/documents';
import EmtpyPlaceholderImg from "dashboard/images/empty-placeholder.png";
import DiscoverProjectPreviewImg from "dashboard/images/discover-project-preview.png";
import DiscoverProjectPreviewImg2x from "dashboard/images/discover-project-preview@2x.png";

const SKETCH_IMPORT_ERRORS = [
  projectsStatuses.IMPORT_FAILED,
  projectsStatuses.IMPORT_NO_ARTBOARD,
  projectsStatuses.IMPORT_OBSOLETE_FILE,
];

const SKETCH_ERROR_TO_REASON_MAP = {
  [projectsStatuses.IMPORT_FAILED]: 'Failed',
  [projectsStatuses.IMPORT_NO_ARTBOARD]: 'No artboard',
  [projectsStatuses.IMPORT_OBSOLETE_FILE]: 'Old version',
};

const UXPFILE_IMPORT_ERROR = projectsStatuses.UXPFILE_IMPORT_FAILED;

export default class InsideProjectBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldLoadImage: false,
      isDocumentWorking: false,
      isEditing: false,
      isDocumentActionsCloudOpen: false,
      isCloudOpen: false,
      isDiscover: false,
      isWorkingEdit: false,
      isWorkingPreview: false,
    };

    this.image = new Image();
    this.image.onload = () => {
      this.setState({
        shouldLoadImage: true,
      });
    };
  }

  @autobind
  getParentNodeEl() {
    return this.refs.documentBox.parentNode.parentNode;
  }

  getDocumentClassNames() {
    const { currentDocumentsActions, document, errors } = this.props;
    const isFailedReimport = document.status === projectsStatuses.REIMPORT_FAILED;
    const isSketchImportError = SKETCH_IMPORT_ERRORS.indexOf(document.status) !== -1;
    const isError = !_.isUndefined(errors[document.idDocument]);
    const isUXPFileImportError = document.status === UXPFILE_IMPORT_ERROR;

    const noHover = currentDocumentsActions[document.idDocument]
      || this.state.isEditing
      || !document.isConverted
      || isFailedReimport
      || isSketchImportError;
    const isWithError = isError || isFailedReimport || isSketchImportError || isUXPFileImportError;

    return classnames('inside-project-box', {
      hover: this.state.isCloudOpen,
      selected: this.state.isDocumentActionsCloudOpen || document.isChromeExtensionUpload,
      renaming: currentDocumentsActions[document.idDocument] === documentsActionsOnHover.RENAMING,
      deleting: currentDocumentsActions[document.idDocument] === documentsActionsOnHover.REMOVING,
      restoring: currentDocumentsActions[document.idDocument] === documentsActionsOnHover.RESTORING,
      moving: currentDocumentsActions[document.idDocument] === documentsActionsOnHover.MOVING,
      duplicating: document.status === projectsStatuses.DUPLICATED,
      converting: document.isConverted === false,
      'parked-account': this.props.isAccountParked,
      error: isError,
      'uploading-file': document.isFilesUploading && !errors[document.idDocument],
      'deleting-counter': currentDocumentsActions[document.idDocument] === documentsActionsOnHover.COUNTING,
      failed: isFailedReimport,
      'sketch-import-failed': isSketchImportError,
      'uxpfile-import-error': isUXPFileImportError,
      'no-hover': noHover,
      'usability-test': document.isChromeExtensionUpload,
      'with-error': isWithError,
      working: this.state.isDocumentWorking,
    });
  }

  // eslint-disable-next-line complexity
  shouldComponentUpdate(nextProps, nextState) {
    const {
      currentDocumentsActions, canAddEditDeleteApprovals, document, errors, features, commentsCount, isAccountParked,
    } = this.props;

    if (nextProps.currentDocumentsActions[nextProps.document.idDocument]
      || nextProps.currentDocumentsActions[nextProps.document.idDocument] !== currentDocumentsActions[document.idDocument]) {
      return true;
    }

    if (_.isUndefined(errors[document.idDocument]) && !_.isUndefined(nextProps.errors[document.idDocument])) {
      return true;
    }

    if (!_.isEqual(nextProps.document, document)) {
      return true;
    }

    if (!_.isEqual(nextProps.features, features)) {
      return true;
    }

    if (!_.isEqual(nextProps.canAddEditDeleteApprovals, canAddEditDeleteApprovals)) {
      return true;
    }

    if (!_.isEqual(nextProps.isAccountParked, isAccountParked)) {
      return true;
    }

    if (!_.isEqual(nextProps.commentsCount, commentsCount)) {
      return true;
    }

    if (!_.isEqual(this.state, nextState)) {
      return true;
    }

    return false;
  }

  hasDuplicationStatusRequestBeenSent(props) {
    const { document, currentDocumentsActions } = props;
    return (currentDocumentsActions[document.idDocument] === documentsActionsOnHover.CHECKING_DUPLICATION_STATUS);
  }

  @autobind
  duplicateDocument() {
    const { document, duplicateDocument } = this.props;
    duplicateDocument(document.idDocument);
  }

  @autobind
  duplicateDocumentWithoutIterations() {
    const { document, duplicateDocument } = this.props;
    duplicateDocument(document.idDocument, true);
  }

  componentDidMount() {
    const { document, requestCheckingDuplicationStatus } = this.props;
    if (document.status === projectsStatuses.DUPLICATED && !document.hasTemporaryId) {
      requestCheckingDuplicationStatus(document.idDocument);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isDiscover, isDocumentWorking } = nextProps;

    this.setState({
      isDiscover,
    });

    if (this.props.document.cover !== nextProps.document.cover || isDiscover) {
      this.setState({
        shouldLoadImage: false,
      });
    }

    if (isDocumentWorking !== this.state.isDocumentWorking) {
      this.setState({
        isDocumentWorking,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { requestCheckingDuplicationStatus, document: { idDocument, hasTemporaryId, status } } = this.props;

    if (status === projectsStatuses.DUPLICATED && !hasTemporaryId && !this.hasDuplicationStatusRequestBeenSent(prevProps) && !this.hasDuplicationStatusRequestBeenSent(this.props)) {
      requestCheckingDuplicationStatus(idDocument);
    }
  }

  renderCover() {
    const coverUrl = this.props.document.cover || EmtpyPlaceholderImg;

    if (this.state.isDiscover) {
      return (
        <img
          src={DiscoverProjectPreviewImg}
          srcSet={`${DiscoverProjectPreviewImg2x} 2x, ${DiscoverProjectPreviewImg} 1x`}
          alt="Discover UXPin"
        />
      );
    }

    if (this.state.shouldLoadImage) {
      // eslint-disable-next-line jsx-a11y/alt-text
      return (<img src={coverUrl} />);
    }

    this.image.src = coverUrl;
  }

  @autobind
  handleInlineEditFocus() {
    this.setState({
      isEditing: true,
    });
  }

  @autobind
  handleInlineEditBlur() {
    this.setState({
      isEditing: false,
    });
  }

  @autobind
  openModalboxDocumentChangeName(e) {
    e.preventDefault();
    this.props.openModalboxDocumentChangeName(this.props.document);
  }

  @autobind
  openModalboxDocumentChangeProject(e) {
    e.preventDefault();
    this.props.openModalboxDocumentChangeProject(this.props.document);
  }

  @autobind
  openModalboxDocumentDelete(e) {
    e.preventDefault();
    this.props.openModalboxDocumentDelete(this.props.document);
  }

  @autobind
  openDocumentActionsCloud() {
    this.setState({
      isDocumentActionsCloudOpen: true,
    });
  }

  @autobind
  closeDocumentActionsCloud() {
    this.setState({
      isDocumentActionsCloudOpen: false,
    });
  }

  @autobind
  openApprovalProcess(e) {
    e.preventDefault();
    const { document, isApprovalProcessEnabled, openApprovalProcess, openFeatureUpgrade } = this.props;

    if (!isApprovalProcessEnabled) {
      openFeatureUpgrade();
      return;
    }

    openApprovalProcess(document);
  }

  @autobind
  setOpen(isCloudOpen) {
    if (this.state.isCloudOpen !== isCloudOpen) {
      this.setState({ isCloudOpen });
    }
  }

  @autobind
  toggleButtonsWorkingStates(isWorkingEdit, isWorkingPreview) {
    this.setState({
      isWorkingEdit,
      isWorkingPreview,
    });
  }

  render() {
    const {
      document, cancelConversion, commentsCount, deleteDocument, removeDocumentPermanently, removingCounters,
      restoreDocument, discardFailedReimport, discardFailedSketchImport, stopActionOnDocument, errors,
      features, renameDocument, canAddEditDeleteApprovals, setDocumentWorkingState,
      cancelDuplication,
    } = this.props;

    const editUrl = getEditUrl(document, features);

    return (
      <section className={this.getDocumentClassNames()} ref="documentBox">
        <header className="box-header">
          <DocumentHeader
            autoClose
            defaultPosition={cloudPositions.BOTTOM}
            document={document}
            editUrl={editUrl}
            handleInlineEditFocus={this.handleInlineEditFocus}
            handleInlineEditBlur={this.handleInlineEditBlur}
            renameDocument={renameDocument}
            setDocumentWorkingState={setDocumentWorkingState}
            setOpen={this.setOpen}
            toggleButtonsWorkingStates={this.toggleButtonsWorkingStates}
            triggerType={triggerTypes.HOVER}
          />
        </header>
        <section className="box-body">
          <figure className="thumbnail">
            {this.renderCover()}
            <span className="no-image">No image available</span>
          </figure>
          <div className="hover-actions">
            <ProjectBoxActionsTop
              openModalboxShare={this.props.openModalboxShare}
            />
            <ProjectBoxActionsCenter
              document={document}
              editUrl={editUrl}
              hasAccessToEditor={this.props.hasAccessToEditor}
              isAccountParked={this.props.isAccountParked}
              isDocumentWorking={this.state.isDocumentWorking}
              isWorkingEdit={this.state.isWorkingEdit}
              isWorkingPreview={this.state.isWorkingPreview}
              setDocumentWorkingState={setDocumentWorkingState}
              toggleButtonsWorkingStates={this.toggleButtonsWorkingStates}
            />
            <ProjectBoxActionsBottom
              document={document}
              features={features}
              commentsCount={commentsCount}
              getParentNodeEl={this.getParentNodeEl}
              startChromeExtensionUpload={this.props.startChromeExtensionUpload}
              discardChromeExtensionUpload={this.props.discardChromeExtensionUpload}
              duplicateDocument={this.duplicateDocument}
              duplicateDocumentWithoutIterations={this.duplicateDocumentWithoutIterations}
              projectsList={this.props.projectsList}
              openDocumentActionsCloud={this.openDocumentActionsCloud}
              closeDocumentActionsCloud={this.closeDocumentActionsCloud}
              isAccountParked={this.props.isAccountParked}
              openModalboxDocumentChangeName={this.openModalboxDocumentChangeName}
              openModalboxDocumentChangeProject={this.openModalboxDocumentChangeProject}
              openModalboxDocumentDelete={this.openModalboxDocumentDelete}
              openModalboxDesignCritique={this.props.openModalboxDesignCritique}
              openApprovalProcess={this.openApprovalProcess}
              canAddEditDeleteApprovals={canAddEditDeleteApprovals}
              hasAccessToEditor={this.props.hasAccessToEditor}
            />
          </div>
          <ProjectBoxPending
            cancelConversion={cancelConversion}
            cancelDuplication={cancelDuplication}
            deleteDocument={deleteDocument}
            document={document}
            stopActionOnDocument={stopActionOnDocument}
            removeDocumentPermanently={removeDocumentPermanently}
            restoreDocument={restoreDocument}
            discardFailedReimport={discardFailedReimport}
            discardFailedSketchImport={discardFailedSketchImport}
            error={errors[document.idDocument]}
            value={document.progress}
            counter={removingCounters[document.idDocument]}
          />
        </section>
      </section>
    );
  }
}

InsideProjectBox.propTypes = {
  cancelDuplication: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  discardFailedReimport: PropTypes.func.isRequired,
  discardFailedSketchImport: PropTypes.func.isRequired,
  document: PropTypes.object.isRequired,
  isDiscover: PropTypes.bool.isRequired,
  openFeatureUpgrade: PropTypes.func.isRequired,
  openApprovalProcess: PropTypes.func.isRequired,
  openModalboxDocumentChangeName: PropTypes.func.isRequired,
  openModalboxDocumentChangeProject: PropTypes.func.isRequired,
  openModalboxDocumentDelete: PropTypes.func.isRequired,
  projectsList: PropTypes.array.isRequired,
  removingCounters: PropTypes.object.isRequired,
  restoreDocument: PropTypes.func.isRequired,
  stopActionOnDocument: PropTypes.func.isRequired,
};

InsideProjectBox.defaultProps = {
  cancelDuplication: _.noop,
  document: {},
  openApprovalProcess: _.noop,
  openModalboxApprovalProcess: _.noop,
  openModalboxDocumentChangeName: _.noop,
  openModalboxDocumentChangeProject: _.noop,
  openModalboxDocumentDelete: _.noop,
  projectsList: [],
  removingCounters: {},
  restoreDocument: _.noop,
  stopActionOnDocument: _.noop,
};
